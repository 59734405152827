<template>
    <layout-full-width title="Packet Plus">

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
export default {
    name: "PagePacketPlus",
    components: { LayoutFullWidth }
}
</script>

<style scoped>

</style>